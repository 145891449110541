import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Box } from '@theme-ui/components'

import Seo from '../components/shared/seo'
import Layout from '../components/shared/layout'
// import Tab from '../components/tabs/tab'
import Hero from '../components/shared/hero'
import Table from '../components/tabs/table'

class TabsIndex extends React.Component {
  render() {
    const tabs = get(this, 'props.data.allContentfulTabPage.nodes')
    return (
      <Layout location="">
        <Seo title="Tabs" />
        <Hero title="Tabs" />
        <Box
          sx={{
            p: 4,
            m: '0 auto',
            maxWidth: '50rem',
          }}
        >
          <Table tabs={tabs}/>
        </Box>
          {/*
          {tabs.map((t) => (
            <Tab tab={t} />
          ))}
          */}
      </Layout>
    )
  }
}

export default TabsIndex

export const pageQuery = graphql`
  query {
    allContentfulTabPage {
      nodes {
        slug
        tuning
        title
        tutorialVideo
        difficulty
        artist {
          title
        }
        pdfTab {
          file {
            url
          }
        }
      }
    }
  }
`
